<template>
  <div>
    <b-form-select :value="yearMonth" @change="onSelect" :options="options" :disabled="disabled"/>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'YearMonthInput',
  props: {
    value: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default() {
        const startYear = 2020; // no finance module before this year
        const monthZeroPad = num => String(num).padStart(2, '0');

        const now = moment().utc();
        const year = now.year();
        const month = now.month() + 1;

        const options = [ { value: null, text: '' } ];
        for (let i = year; i >= startYear; i--) {
          for (let j = 12; j >= 1; j--) {
            if (i === year && j > month) {
              // eslint-disable-next-line no-continue
              continue;
            }
            const opt = `${i}-${monthZeroPad(j)}`;
            options.push({ value: opt, text: opt });
          }
        }
        return options;
      },
    },
  },
  data() {
    return {
      yearMonth: null,
    };
  },
  beforeMount() {
    this.yearMonth = this.value;
  },
  methods: {
    onSelect(selectedOption) {
      this.yearMonth = selectedOption;
      this.$emit('input', selectedOption);
    },
  },
};
</script>
