<template>
  <div>
    <b-spinner v-if="!pageReady"/>
    <div v-else class="p-3">
      <header class="d-flex flex-row justify-content-between mb-1">
        <h3>{{ translations.monthly_revenue.page_title }}</h3>
      </header>

      <div class="mr-4">
        <monthly-revenue-list-filters :disabled="loading" @apply-filters="applyFilters"/>
      </div>

      <financial-list
        class="mt-4"
        v-model="currentPage"
        :header-data="TABLE_HEADERS"
        :events="TABLE_EVENTS"
        :data="elements"
        :disabled="loading"
        :items-per-page="itemsPerPage"
        :total="total"
        :no-items-message="translations.monthly_revenue.table.empty_list"
        itemRefKey="id"
        @page-changed="loadRevenues"
        @row-details="openDetails"
        @row-export-summary="exportSummary"
        @row-export-details="exportDetails"/>
    </div>
  </div>
</template>

<script>
import FinancialList, { associateHeaderDataItem } from '@/components/Financial/FinancialList.vue';
import errorHandler from '@/mixins/errorHandler';
import translations from '@/translations';
import MonthlyRevenueListFilters from '@/views/Finance/MonthlyRevenue/MonthlyRevenueListFilters.vue';
import utils from '@/scripts/tools/utils';
import { formatDate } from '@/helpers/finance';

export default {
  name: 'FinanceMonthlyRevenueList',
  components: { MonthlyRevenueListFilters, FinancialList },
  mixins: [ errorHandler ],
  created() {
    this.TABLE_HEADERS = [
      associateHeaderDataItem(this.translations.monthly_revenue.table.client, 'client_name'),
      associateHeaderDataItem(this.translations.monthly_revenue.table.month, 'year_month'),
      associateHeaderDataItem(this.translations.monthly_revenue.table.gross_amount, 'gross_amount'),
      associateHeaderDataItem(this.translations.monthly_revenue.table.revenue_share_amount, 'revenue_share_amount'),
      associateHeaderDataItem(this.translations.monthly_revenue.table.net_amount, 'net_amount'),
    ];
    this.TABLE_EVENTS = {
      'row-details': {
        title: translations.finance.financial_list.icons.details,
        icon: 'eye',
      },
      'row-export-summary': {
        title: translations.finance.statements.global.export_summary,
        icon: 'file',
      },
      'row-export-details': {
        title: translations.finance.statements.global.export_details,
        icon: 'download',
      },
    };
  },
  data() {
    return {
      translations: translations.finance,
      pageReady: false,
      loading: true,
      appliedFilters: {},
      elements: [],
      itemsPerPage: 10,
      currentPage: 1,
      total: 0,
    };
  },
  async beforeMount() {
    await this.loadRevenues(this.currentPage);
    this.pageReady = true;
  },
  methods: {
    async loadRevenues(page) {
      this.loading = true;
      const offset = (page - 1) * this.itemsPerPage;
      const limit = this.itemsPerPage;
      try {
        const response = await this.$store.dispatch('Financial/getMonthlyRevenues', {
          limit,
          offset,
          ...this.appliedFilters,
        });
        let list = [];
        if (response && response.data && response.data.data) {
          this.total = response.data.data.total;
          list = response.data.data.items;
        } else {
          this.total = 0;
        }
        list.forEach(e => {
          e.gross_amount = this.formatValue(e.gross_amount);
          e.revenue_share_amount = this.formatValue(e.revenue_share_amount);
          e.net_amount = this.formatValue(e.net_amount);
        });
        this.elements = list;
      } catch (_) {
        this.$noty.error(`${this.translations.monthly_revenue.error_loading}.`);
      } finally {
        this.loading = false;
      }
    },
    formatValue(value) {
      const number = Number.parseFloat(value);
      if (Number.isNaN(number)) {
        return value;
      }
      if (Math.abs(number) < 0.0001) {
        return '0.00';
      }

      return number.toFixed(2);
    },
    applyFilters(filters) {
      this.currentPage = 1;
      this.appliedFilters = filters;
      this.loadRevenues(this.currentPage);
    },
    openDetails(_, mr) {
      this.$router.push(`/finance/monthly-revenue/${mr.client_id}/${mr.year_month}`);
    },
    async exportSummary(_, mr) {
      try {
        const { data: { data: mrData } } = await this.$store.dispatch('Financial/getMonthlyRevenue', {
          clientId: mr.client_id,
          yearMonth: mr.year_month,
        });

        const { data } = await this.$store.dispatch('Financial/exportStatementSummaryMerge', {
          selected_ids: (mrData.statements_ids || []).join(','),
        });

        const dateStr = formatDate(new Date(), 'YYYYMMDD_HHmmss');
        const fileName = `revenue_summary_${dateStr}_${mrData.client_name}_${mrData.year_month}.csv`;
        utils.downloadFile(data, fileName);
      } catch (error) {
        let errorMsg = this.translations.global.errors.export_csv;
        if (error?.response?.data?.error?.detail) {
          errorMsg = `${errorMsg}: ${error.response.data.error.detail}`;
        }
        this.$noty.error(errorMsg);
      }
    },
    async exportDetails(_, mr) {
      try {
        const { data: { data: mrData } } = await this.$store.dispatch('Financial/getMonthlyRevenue', {
          clientId: mr.client_id,
          yearMonth: mr.year_month,
        });

        const { data } = await this.$store.dispatch('Financial/exportTransactions', {
          statement_ids: (mrData.statements_ids || []).join(','),
        });

        const dateStr = formatDate(new Date(), 'YYYYMMDD_HHmmss');
        const fileName = `revenue_transactions_${dateStr}_${mrData.client_name}_${mrData.year_month}.csv`;
        utils.downloadFile(data, fileName);
      } catch (error) {
        let errorMsg = this.translations.global.errors.export_csv;
        if (error?.response?.data?.error?.detail) {
          errorMsg = `${errorMsg}: ${error.response.data.error.detail}`;
        }
        this.$noty.error(errorMsg);
      }
    },
  },
};
</script>
